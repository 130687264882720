
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import AuthModule from "@/store/modules/AuthModule";
import { Base64Utils } from "@/latipay/utils/Base64Utils";
import { ref } from "vue";
// import * as Buffer from "buffer";
declare const Buffer
export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const userEmail = ref('XXXX@latipay.net');
    const userName = ref('XXXX');
    const username = store.getters.currentUser?.username;
    const email = store.getters.currentUser?.email;
    const user = AuthModule.parseAccessToken(JwtService.getToken());


    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };



    const accessToken = JwtService.getToken();
    if (accessToken) {
      const tokenInfo = accessToken.toString().split(".");
      // const buf = new Buffer(tokenInfo[1], 'base64');
      const payload = JSON.parse(Buffer.from(tokenInfo[1], "base64").toString("binary"));
      console.log(payload);
      userEmail.value = payload.username;
      userName.value = payload.name;
    }

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      userEmail,
      userName
    };
  },
});
